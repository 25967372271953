<template>
  <Transition name="popup">
    <div
      v-if="showPopup"
      class="overflow-hidden w-full h-full bg-white absolute top-0 left-0"
      style="z-index: 10000"
    >
      <div
        class="flex justify-content-center flex-column align-items-center w-8 h-full m-auto"
      >
        <span class="text-center text-2xl mb-3">АЗС Локатор</span>
        <div class="w-full flex flex-column align-items-center">
          <span class="mb-3 text-center">Выберите вашу топливную карту</span>
          <div class="grid grid-nogutter">
            <div
              v-for="card in $store.state.cards"
              class="col-6 sm:col-4 md:col-4 lg:col-4 flex flex-column align-items-center justify-content-between pb-3"
              :class="{
                'xl:col-3': $store.state.cards.length > 4,
                'xl:col-4': $store.state.cards.length <= 4,
              }"
              @click="getChosenCard(card.id)"
            >
              <img
                :src="`${card.icon_url}`"
                :alt="`${card.name}`"
                class="hover-zoom cursor-pointer shadow-5 card-img h-4rem w-6rem sm:h-5rem md:h-5rem xl:h-6rem lg:h-6rem sm:w-8rem md:w-8rem xl:w-9rem lg:w-9rem border-round-lg"
              />
              <p
                class="card-name text-xs sm:text-xs md:text-base lg:text-base xl:text-base"
              >
                <span>{{ card.name }}</span
                >,
                <span style="color: var(--primary-color)">{{ card.id }}</span>
              </p>
            </div>
          </div>
          <Button
            label="ОТКРЫТЬ КАРТУ БЕЗ РЕГИСТРАЦИИ"
            outlined
            size="small"
            @click="closePopupAndInitMapData"
          />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "PopUp",
  data: () => ({
    filteredCards: null,
    cards: null,
    showPopup: true,
    chosenCard: null,
    cardStyle: null,
  }),
  methods: {
    closePopupAndInitMapData() {
      this.showPopup = false;
      this.$emit("init-map-data-on-popup-close");
    },
    getChosenCard(cardId) {
      this.showPopup = false;
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.set("emitents", cardId);
      url.search = params.toString();
      window.history.replaceState(null, null, url.toString());
      this.$store.commit("setCardNumberFromPopup", cardId);
      this.$emit("get-chosen-card", cardId, this.showPopup);
    },
  },
};
</script>

<style scoped lang="scss">
.hover-zoom {
  transition: transform 0.2s; /* Animation */
}

.hover-zoom:hover {
  transform: scale(1.1);
}

.popup-leave-active {
  transition: all 0.5s ease;

  &.popup-leave-to {
    opacity: 0;
  }
}

.card-leave-active {
  transition: transform 0.5s ease;

  &.card-leave-to {
    transform: translate(20px, 20px);
  }
}

.popup__header {
  text-align: center;
  font-size: 2rem;

  @media screen and (max-width: 870px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 1.4rem;
  }
}

.p-error {
  font-size: 0.7rem;
}
</style>

import {
  getMapInstance,
  getRoute,
  setPolyLine,
  setRectangle,
  setRectangleCollection,
} from "@/services/nonReactiveGlobalVariables";
import {
  createNewPathPolyline,
  getClosestDirect,
  requestStationsIfRouteWasBuild,
} from "@/services/utils";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";

export const nearestStationsToRouteMixin = {
  mixins: [requestsAPIMixin],
  methods: {
    getNearestStationsToRouteMixinMethod(paramsData) {
      getRoute().model.events.add("requestsuccess", async () => {
        this.$store.commit("setBoundsCheck", false);
        const pathPolyline = getRoute()
          .getRoutes()
          .toArray()
          .map(function (path) {
            return createNewPathPolyline(path);
          });
        const polylineGeoObjectCollection = new ymaps.GeoObjectCollection(
          {
            children: pathPolyline,
          },
          { visible: false }
        );
        getMapInstance().geoObjects.add(polylineGeoObjectCollection);
        const rectangleBounds = [
          getClosestDirect(
            polylineGeoObjectCollection.getBounds()[0],
            225,
            1000
          ),
          getClosestDirect(
            polylineGeoObjectCollection.getBounds()[1],
            45,
            1000
          ),
        ];

        const rectangleGeoObjectCollection = new ymaps.GeoObjectCollection();

        const rectangle = new ymaps.Rectangle(
          rectangleBounds,
          {},
          {
            visible: false,
          }
        );
        rectangleGeoObjectCollection.add(rectangle);
        getMapInstance().geoObjects.add(rectangle);

        setRectangleCollection(rectangleGeoObjectCollection);

        const [minLatLon, maxLatLon] = polylineGeoObjectCollection.getBounds();
        const newCoords = {
          minLat: minLatLon[0],
          maxLat: maxLatLon[0],
          minLon: minLatLon[1],
          maxLon: maxLatLon[1],
        };
        this.$store.commit("setNewCoords", newCoords);

        this.$store.commit("setRouteWasBuild", true);

        setPolyLine(pathPolyline);
        setRectangle(rectangle);

        const result = await this.requestStationsMixinMethod(
          "",
          true,
          paramsData
        );
        await requestStationsIfRouteWasBuild(result);

        this.requestNearestListStationsMixinMethod();
        this.$store.commit("setShowSpinner", false);
        getMapInstance().geoObjects.add(getRoute());
      });
    },
  },
};

import axios from "axios";
import { setNearestStationsToRouteCount } from "@/services/nonReactiveGlobalVariables";

const BASE_URL = "https://loc.dt-pro.ru/api/";

async function getItem(url, params = undefined, headers = undefined) {
  try {
    return await (
      await axios.get(BASE_URL + url, { ...params, ...headers })
    ).data;
  } catch (error) {
    console.error(error);
  }
}

async function getBrands() {
  return getItem("brands/");
}

async function getServices(params, emitentsID) {
  if (emitentsID) {
    params.set("emitents", emitentsID);
  }
  return getItem("services/", { params });
}

async function getAdditionals(params, emitentsID) {
  if (emitentsID) {
    params.set("emitents", emitentsID);
  }
  return getItem("additionals/", { params });
}

async function getEmitents(params, filialCode) {
  params.set("filial", filialCode);
  return getItem("emitents/", { params });
}

async function getStationDetails(stationId) {
  return getItem(`station/${stationId}/`);
}

async function getStationList(params) {
  return getItem("stations/", params);
}

async function getExcelStationList(data, params) {
  try {
    const response = await axios.post(
      BASE_URL + "get_stations_in_excel_file/",
      data,
      { ...params, responseType: "blob" }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${new Date().toLocaleString("ru-RU")} АЗС станции.xlsx`
    );
    link.click();
  } catch (error) {
    console.error(error);
  }
}

async function getStationsPointForRoute(data, params) {
  try {
    const response = await axios.post(
      BASE_URL + "nearest-stations-to-route/",
      data,
      params
    );
    setNearestStationsToRouteCount(response.data["total"]["count"]);
    return response.data["response"];
  } catch (error) {
    console.error(error);
  }
}

async function getCheapestStationsFuel(data, params) {
  try {
    const response = await axios.post(
      BASE_URL + "get-cheapest-stations-route/",
      data,
      params
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getNearestStationsToUser(params) {
  return getItem("nearest-stations/", params);
}

async function getFilial(lookupValue) {
  return getItem(`filial/${lookupValue}`);
}

export {
  BASE_URL,
  getCheapestStationsFuel,
  getExcelStationList,
  getFilial,
  getAdditionals,
  getBrands,
  getEmitents,
  getServices,
  getStationDetails,
  getNearestStationsToUser,
  getStationList,
  getStationsPointForRoute,
};

<template>
  <ToggleButton
    v-model="stationIds"
    v-tooltip.left="toggleButtonText"
    :disabled="$store.state.routePointsArray.length <= 1"
    aria-label=""
    offIcon="pi pi-plus"
    onIcon="pi pi-check"
    onLabel=""
  />
</template>

<script>
export default {
  name: "ToggleButtonComponent",
  data: () => ({
    toggleButtonText: "Включить станцию в ссылку для маршрута",
  }),
  props: { stationId: String | Number },
  computed: {
    stationIds: {
      get() {
        const arrayIndex = this.$store.state.stationIds.indexOf(this.stationId);
        if (arrayIndex > -1) {
          this.toggleButtonText = "Удалить станцию из ссылки на маршрут";
        } else {
          this.toggleButtonText = "Включить станцию в ссылку для маршрута";
        }
        return arrayIndex > -1;
      },
      set(value) {
        if (value) {
          this.$store.commit("pushStationIds", this.stationId);
          this.$toast.add({
            severity: "info",
            summary: "Инфо",
            detail: "Точка добавлена в ссылку на маршрут!",
            life: 3000,
          });
        } else {
          this.$store.commit("spliceStationIds", this.stationId);
          this.$toast.add({
            severity: "info",
            summary: "Инфо",
            detail: "Точка удалена из ссылки на маршрут!",
            life: 3000,
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>

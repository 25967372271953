<template>
  <Toast class="w-22rem" position="bottom-left" />
  <div class="flex flex-column align-items-center justify-content-center">
    <div :class="`filter ${$store.state.addClassWidth}`">
      <div
        v-for="(key, index) in $store.state.searchAddressArray"
        :key="index"
        :class="`filter__block ${$store.state.addClassWidth}`"
      >
        <label :for="`addressesValue${index}`">Адрес {{ index + 1 }}</label>
        <div class="flex mt-2 p-inputgroup flex-1">
          <AutoComplete
            v-model="key.address"
            :delay="500"
            :input-id="`addressesValue${index}`"
            :pt="{
              panel: { class: 'w-4rem' },
            }"
            :suggestions="suggestedAddresses"
            placeholder="Адрес"
            @complete="getSuggestAddressMixinMethod(key.address)"
          />
          <Button
            v-if="index === 0"
            icon="pi pi-map-marker"
            outlined
            @click="setUserPositionMixinMethod(true)"
          />
          <Button
            icon="pi pi-times"
            outlined
            style="color: #ff6464"
            @click="deleteSearchInput(index)"
          />
        </div>
      </div>
      <div
        :class="`filter__block flex flex-column ${$store.state.addClassWidth}`"
      >
        <Button
          :label="
            $store.state.searchAddressArray.length === 0
              ? 'Добавить маршрут'
              : 'Добавить точку к маршруту'
          "
          class="w-full font-light text-xs mb-2"
          icon="pi pi-plus"
          outlined
          size="small"
          @click="addSearchInput()"
        />
        <Button
          class="w-full font-light text-xs mb-2"
          icon="pi pi-eraser"
          label="Очистить маршрут"
          outlined
          size="small"
          @click="clearRoutes()"
        />
        <Button
          :disabled="isSearchAddressArrayIsEmpty()"
          class="w-full font-light text-xs mb-2 surface-0 text-0 bg-primary"
          icon="pi pi-map-marker"
          label="Построить маршрут"
          outlined
          size="small"
          @click="setRouteByArrOfPoints()"
        />
        <div class="mt-2">
          <RouteToolbar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAddressesMixin } from "@/mixins/getAddressesMixin";
import { isMobile, isSearchAddressArrayIsEmpty } from "@/services/utils";
import {
  getLOMInstance,
  getMapInstance,
  getPointCollection,
  getRectangleCollection,
  getRoute,
  getURLSearchParamsInstance,
  setPolyLine,
  setRectangle,
} from "@/services/nonReactiveGlobalVariables";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";
import { setPositionsMixins } from "@/mixins/setPositionsMixin";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";
import { setRouteMixin } from "@/mixins/setRouteMixin";
import RouteToolbar from "@/components/UtilsComponents/RouteToolbar.vue";

export default {
  name: "SideNav",
  components: { RouteToolbar },
  mixins: [
    requestsAPIMixin,
    setPositionsMixins,
    getAddressesMixin,
    nearestStationsToRouteMixin,
    setRouteMixin,
  ],
  methods: {
    isSearchAddressArrayIsEmpty,
    clearRoutes() {
      try {
        const params = getURLSearchParamsInstance();
        if (params.has("selectedPoints")) {
          params.delete("id");
        }
        this.$store.commit("setSelectedCheapestFuel", null);
        this.$store.commit("setSearchAddress", null);
        this.$store.commit("clearSearchAddressArray");
        this.$store.commit("clearRoutePointsArray");
        this.$store.commit("setBoundsCheck", true);
        this.$store.commit("setNearestStationsToRoute", []);
        this.$store.commit("setRouteWasBuild", false);
        this.$store.commit("clearStationIds");
        this.$store.commit("clearStationsIdsForNearestRouteStations");
        getMapInstance().geoObjects.remove(getRoute());
        getRectangleCollection().removeAll();
        getPointCollection().removeAll();
        getLOMInstance().objects.removeAll();
        setRectangle(undefined);
        setPolyLine(undefined);
        if (isMobile()) {
          this.$store.commit("setSideBarVisible", false);
        }
        const [minLat, minLon] = this.$store.state.bounds[0];
        const [maxLat, maxLon] = this.$store.state.bounds[1];
        const newCoords = { minLat, maxLat, minLon, maxLon };

        this.$store.commit("setNewCoords", newCoords);
        this.requestStationsMixinMethod();
      } catch (e) {
        console.error(e);
      } finally {
        this.$toast.add({
          severity: "info",
          summary: "Инфо",
          detail: "Маршрут очищен!",
          life: 3000,
        });
      }
    },
    async deleteSearchInput(index) {
      this.$store.commit("spliceSearchAddressArray", index);
      this.$store.commit("spliceRoutePointsArray", index);

      if (this.$store.state.routePointsArray.length <= 1) {
        getMapInstance().geoObjects.remove(getRoute());
        if (getRectangleCollection()) {
          getRectangleCollection().removeAll();
        }
        this.$store.commit("clearSearchAddressArray");
        this.$store.commit("clearRoutePointsArray");
        this.$store.commit("setBoundsCheck", true);
        this.$store.commit("setNearestStationsToRoute", []);
        const [minLat, minLon] = this.$store.state.bounds[0];
        const [maxLat, maxLon] = this.$store.state.bounds[1];
        const newCoords = { minLat, maxLat, minLon, maxLon };

        this.$store.commit("setNewCoords", newCoords);
        await this.requestStationsMixinMethod();
      } else {
        await this.setRouteByArrOfPointsMixin(
          this.$store.state.searchAddressArray,
          "addressNames"
        );
        this.getNearestStationsToRouteMixinMethod();
      }
    },
    addSearchInput() {
      this.$store.commit("setSearchAddressArray", "");
    },
    async setRouteByArrOfPoints() {
      await this.setRouteByArrOfPointsMixin(
        this.$store.state.searchAddressArray,
        "addressNames"
      );
      this.$store.commit("setSideBarVisible", false);
      this.getNearestStationsToRouteMixinMethod();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  height: 100%;

  &:nth-child(1) {
    margin: 0;
  }
}

.filter__block {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;

  &:nth-child(1) {
    margin: 0;
  }
}

.filter__autocomplete-flex {
  display: flex;
  margin-top: 10px;
}

.filter__div {
  margin: 0 0 15px 0;
}

.filter__div-input {
  width: 100%;
}

.filter__block-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;

  span {
    padding-left: 10px;
  }
}
</style>

<template>
  <Toolbar :pt="{ start: { class: 'w-full' } }" class="mt-2">
    <template #start>
      <div class="card w-full flex flex-column justify-content-center">
        <span class="mb-2">Управление маршрутом</span>
        <div class="">
          <Button
            aria-controls="routeMenu"
            aria-haspopup="true"
            class="w-full"
            outlined
            size="small"
            type="button"
            @click="toggleRouteMenu"
          >
            <span
              class="p-button-icon p-button-icon-left pi pi-share-alt"
            ></span>
            <span class="p-button-label">Действия с маршрутом</span>
            <span
              class="p-button-icon p-button-icon-right pi pi-angle-down"
            ></span>
          </Button>
          <Menu
            id="routeMenu"
            ref="routeMenu"
            :model="routeSettings"
            :popup="true"
            class="h-16rem"
          />
        </div>
        <div class="mt-2">
          <Dropdown
            v-model="selectedCheapestFuel"
            :disabled="isSearchAddressArrayAndRoutePointsArrayIsEmpty()"
            :highlightOnSelect="false"
            :options="$store.state.services"
            class="w-full"
            filter
            optionLabel="name"
            placeholder="Показать АЗС с низкой ценой"
            showClear
            @update:model-value="requestCheapestStationsFuel"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="mt-3">
          <Button
            class="h-2rem w-1rem"
            icon="pi pi-info-circle"
            outlined
            size="small"
            @click="dialogAZSInfoVisible = true"
          />
          <Dialog
            v-model:visible="dialogAZSInfoVisible"
            class="w-20rem xl:w-40rem lg:w-35rem md:w-30rem sm:w-25rem"
            header="Дешевые станции"
            modal
          >
            <span class="p-text-secondary block mb-5"
              >Выделяем зеленым цветом первые 10% точек с низкой ценой</span
            >
          </Dialog>
        </div>
      </div>
    </template>
  </Toolbar>
</template>

<script>
import {
  copyRouteWithoutPoints,
  copyRouteWithPoints,
  isSearchAddressArrayAndRoutePointsArrayIsEmpty,
  isSearchAddressArrayAndStationIdsIsEmpty,
  isSearchAddressArrayIsEmpty,
  openRouteInYandexMaps,
  requestExcelStationsMixinMethod,
} from "@/services/utils";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";

export default {
  name: "RouteToolbar",
  mixins: [requestsAPIMixin],
  computed: {
    selectedCheapestFuel: {
      get() {
        return this.$store.state.selectedCheapestFuel;
      },
      set(value) {
        this.$store.commit("setSelectedCheapestFuel", value);
      },
    },
    routeSettings() {
      return [
        {
          label: "Действия с маршрутом",
          icon: "pi pi-cog",
          items: [
            {
              label: "Выгрузить в Excel все точки",
              icon: "pi pi-file-excel",
              command: () => {
                this.$toast.add({
                  severity: "info",
                  summary: "Инфо",
                  detail:
                    "Точки в эксель будут выгружены через несколько секунд...",
                  life: 3000,
                });
                requestExcelStationsMixinMethod();
              },
              disabled: () => isSearchAddressArrayIsEmpty(),
            },
            {
              label: "Скопировать маршрут со всеми точками",
              icon: "pi pi-copy",
              command: () => {
                this.$toast.add({
                  severity: "info",
                  summary: "Инфо",
                  detail: "Ссылка на маршрут со всеми точками скопирована!",
                  life: 3000,
                });
                copyRouteWithoutPoints();
              },
              disabled: () => isSearchAddressArrayIsEmpty(),
            },
            {
              label: "Скопировать маршрут с выделенными точками",
              icon: "pi pi-copy",
              command: () => {
                this.$toast.add({
                  severity: "info",
                  summary: "Инфо",
                  detail:
                    "Ссылка на маршрут c выделенными точками скопирована!",
                  life: 3000,
                });
                copyRouteWithPoints();
              },
              disabled: () => isSearchAddressArrayAndStationIdsIsEmpty(),
            },
            {
              label: "Открыть в Яндекс Карты",
              icon: "pi pi-map",
              command: () => openRouteInYandexMaps(),
              disabled: () => isSearchAddressArrayAndRoutePointsArrayIsEmpty(),
            },
          ],
        },
      ];
    },
  },
  data: () => ({
    dialogAZSInfoVisible: false,
  }),
  methods: {
    isSearchAddressArrayAndRoutePointsArrayIsEmpty,
    toggleRouteMenu(event) {
      this.$refs.routeMenu.toggle(event);
    },
  },
};
</script>

<style lang="scss" scoped></style>

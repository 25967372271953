import {
  getMapInstance,
  getPointCollection,
  getRoute,
  setRoute,
} from "@/services/nonReactiveGlobalVariables";
import {
  changeSideBarHight,
  createNewPoint,
  isMobile,
  returnAddressNameByCoordinates,
  setNewRoute,
  setPointsArrDependsOnAddressType,
} from "@/services/utils";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";

export const setRouteMixin = {
  mixins: [nearestStationsToRouteMixin],
  methods: {
    async setRouteMixinMethodByLatLon(latitude, longitude) {
      getPointCollection().add(createNewPoint(latitude, longitude));

      getMapInstance().geoObjects.add(getPointCollection());
      this.$store.commit("setRoutePointsArray", [latitude, longitude]);

      this.$store.commit(
        "setSearchAddressArray",
        await returnAddressNameByCoordinates([latitude, longitude])
      );

      if (this.$store.state.routePointsArray.length === 2) {
        getPointCollection().removeAll();
      }

      if (this.$store.state.routePointsArray.length >= 2) {
        if (getRoute()) {
          getRoute().model.setReferencePoints(
            this.$store.state.routePointsArray,
            [],
            true
          );
          getPointCollection().removeAll();
        } else {
          this.$store.commit("clearStationIds");
          this.$store.commit("clearStationsIdsForNearestRouteStations");
          setRoute(await setNewRoute(this.$store.state.routePointsArray));
        }
        this.$store.commit("setShowSpinner", true);
        this.getNearestStationsToRouteMixinMethod();
      }
    },
    async setRouteByArrOfPointsMixin(addressArr, coordsType = "addressNames") {
      this.$store.commit("setShowSpinner", true);

      if (isMobile()) {
        this.$store.commit("setSideBarVisible", false);
        const classTransitionNames = [
          "transition-all",
          "transition-duration-500",
        ];
        document
          .getElementById("phoneSidebar")
          .classList.add(...classTransitionNames);
        document
          .getElementById("phoneSidebarBtn")
          .classList.add(...classTransitionNames);
        changeSideBarHight();
      }
      if (this.$store.state.routePointsArray.length) {
        this.$store.commit("clearRoutePointsArray");
      }

      await setPointsArrDependsOnAddressType(addressArr, coordsType);

      if (this.$store.state.routePointsArray.length >= 1) {
        if (getRoute()) {
          getRoute().model.setReferencePoints(
            this.$store.state.routePointsArray,
            [],
            true
          );
        } else {
          this.$store.commit("clearStationIds");
          this.$store.commit("clearStationsIdsForNearestRouteStations");
          setRoute(await setNewRoute(this.$store.state.routePointsArray));
        }
      }
    },
  },
};

import { createStore } from "vuex";

export default createStore({
  state: () => ({
    myPositionCoords: [],
    newCoords: null,
    nearestStations: [],
    nearestStationsToRoute: [],
    cheapestStationsFuel: [],
    searchAddressArray: [],
    searchAddress: null,
    routePointsArray: [],
    newCoordsNearestStations: null,
    cardNumberFromPopup: null,
    stationIds: [],
    availableCards: null,
    brands: undefined,
    services: undefined,
    additionals: undefined,
    cards: undefined,
    selectedAdditional: [],
    selectedService: [],
    selectedCard: [],
    selectedBrand: [],
    selectedBarcode: "",
    selectedPhone: "",
    selectedCardModel: [],
    stations: [],
    stationData: [],
    sidebarHeight: 40,
    boundsCheck: true,
    bounds: [
      [55.717771511516816, 37.50107133251953],
      [55.78931354450113, 37.717364667480474],
    ],
    routeWasBuild: false,
    nearestStationsToRouteSearchField: "",
    activeTab: 0,
    sideBarVisible: false,
    selectedStationIds: [],
    showSpinner: false,
    filial: undefined,
    stationTypeFilterValues: [],
    selectedPhonePayment: "",
    selectedBarcodePayment: "",
    selectedAZSGazpomneft: "",
    selectedAZSPartners: "",
    selectedAZSFranchisees: "",
    selectedOPTI24: "",
    selectedOPTI: "",
    selectedOnlyAZSStations: "",
    selectedCheapestFuel: undefined,
    sideBarPosition: "right",
    addClassWidth: "w-full",
  }),
  mutations: {
    pushStationIds(state, newStationIds) {
      state.stationIds.push(newStationIds);
    },
    spliceStationIds(state, newStationIds) {
      const arrayIndex = state.stationIds.indexOf(newStationIds);
      if (arrayIndex > -1) {
        state.stationIds.splice(arrayIndex, 1);
      }
    },
    clearStationIds(state) {
      state.stationIds = [];
    },
    setSelectedCheapestFuel(state, newSelectedCheapestFuel) {
      state.selectedCheapestFuel = newSelectedCheapestFuel;
    },
    setAddClassWidth(state, newAddClassWidth) {
      state.addClassWidth = newAddClassWidth;
    },
    setSidebarPosition(state, newSidebarPosition) {
      state.sideBarPosition = newSidebarPosition;
    },
    setSelectedPhonePayment(state, newSelectedPhonePayment) {
      state.selectedPhonePayment = newSelectedPhonePayment;
    },
    setSelectedOnlyAZSStations(state, newSelectedOnlyAZSStations) {
      state.selectedOnlyAZSStations = newSelectedOnlyAZSStations;
    },
    setSelectedBarcodePayment(state, newSelectedBarcodePayment) {
      state.selectedBarcodePayment = newSelectedBarcodePayment;
    },
    setSelectedAZSGazpomneft(state, newSelectedAZSGazpomneft) {
      state.selectedAZSGazpomneft = newSelectedAZSGazpomneft;
    },
    setSelectedAZSPartners(state, newSelectedAZSPartners) {
      state.selectedAZSPartners = newSelectedAZSPartners;
    },
    setSelectedAZSFranchisees(state, newSelectedAZSFranchisees) {
      state.selectedAZSFranchisees = newSelectedAZSFranchisees;
    },
    setSelectedOPTI24(state, newSelectedOPTI24) {
      state.selectedOPTI24 = newSelectedOPTI24;
    },
    setSelectedOPTI(state, newSelectedOPTI) {
      state.selectedOPTI = newSelectedOPTI;
    },
    setNearestStationsToRouteSearchField(
      state,
      newNearestStationsToRouteSearchField
    ) {
      state.nearestStationsToRouteSearchField =
        newNearestStationsToRouteSearchField;
    },
    setFilial(state, newFilial) {
      state.filial = newFilial;
    },
    setRouteWasBuild(state, newRouteWasBuild) {
      state.routeWasBuild = newRouteWasBuild;
    },
    setStationData(state, newStationData) {
      state.stationData = newStationData;
    },
    setSidebarHight(state, newSidebarHight) {
      state.sidebarHeight = newSidebarHight;
    },
    setStationTypeFilter(state, newStationTypeFilter) {
      state.stationTypeFilter = newStationTypeFilter;
    },
    setShowSpinner(state, newShowSpinner) {
      state.showSpinner = newShowSpinner;
    },
    setStationsIdsForNearestRouteStations(
      state,
      newStationsIdsForNearestRouteStations
    ) {
      state.selectedStationIds = newStationsIdsForNearestRouteStations;
    },
    clearStationsIdsForNearestRouteStations(state) {
      state.selectedStationIds = [];
    },
    setSideBarVisible(state, newSideBarVisible) {
      state.sideBarVisible = newSideBarVisible;
    },
    setSelectedCardModel(state, newSelectedCardModel) {
      state.selectedCardModel = newSelectedCardModel;
    },
    setActiveTab(state, newActiveTab) {
      state.activeTab = newActiveTab;
    },
    setBounds(state, newBounds) {
      state.bounds = newBounds;
    },
    setBoundsCheck(state, newBoundsCheck) {
      state.boundsCheck = newBoundsCheck;
    },
    setStations(state, newStations) {
      state.stations = newStations;
    },
    setSelectedAdditional(state, newSelectedAdditional) {
      state.selectedAdditional = newSelectedAdditional;
    },
    setSelectedService(state, newSelectedService) {
      state.selectedService = newSelectedService;
    },
    setSelectedCard(state, newSelectedCard) {
      state.selectedCard = newSelectedCard;
    },
    setSelectedBrand(state, newSelectedBrand) {
      state.selectedBrand = newSelectedBrand;
    },
    setSelectedBarcode(state, newSelectedBarcode) {
      state.selectedBarcode = newSelectedBarcode;
    },
    setSelectedPhone(state, newSelectedPhone) {
      state.selectedPhone = newSelectedPhone;
    },
    setBrands(state, newBrands) {
      state.brands = newBrands;
    },
    setServices(state, newServices) {
      state.services = newServices;
    },
    setAdditionals(state, newAdditionals) {
      state.additionals = newAdditionals;
    },
    setCards(state, newCards) {
      state.cards = newCards;
    },
    setAvailableCards(state, newAvailableCards) {
      state.availableCards = newAvailableCards;
    },
    setCardNumberFromPopup(state, newCardNumberFromPopup) {
      state.cardNumberFromPopup = newCardNumberFromPopup;
    },
    setNewCoordsNearestStations(state, newCoordsNearestStations) {
      state.newCoordsNearestStations = newCoordsNearestStations;
    },
    setRoutePointsArray(state, point) {
      state.routePointsArray.push(point);
    },
    spliceSearchAddressArray(state, item) {
      state.searchAddressArray.splice(item, 1);
    },
    spliceRoutePointsArray(state, item) {
      state.routePointsArray.splice(item, 1);
    },
    clearRoutePointsArray(state) {
      state.routePointsArray = [];
    },
    setMyPositionCoords(state, myPositionCoords) {
      state.myPositionCoords = myPositionCoords;
    },
    setNewCoords(state, newCoords) {
      state.newCoords = newCoords;
    },
    setNearestStations(state, nearestStations) {
      state.nearestStations = nearestStations;
    },
    setNearestStationsToRoute(state, newNearestStationsToRoute) {
      state.nearestStationsToRoute = newNearestStationsToRoute;
    },
    setCheapestStationsFuel(state, newCheapestStationsFuel) {
      state.cheapestStationsFuel = newCheapestStationsFuel;
    },
    updateSearchAddressArray(state, payload) {
      if (state.searchAddressArray.length !== 0) {
        state.searchAddressArray[payload.index].address = payload.address;
      }
    },
    setSearchAddressArray(state, searchAddressArray) {
      state.searchAddressArray.push({ address: searchAddressArray });
    },
    clearSearchAddressArray(state) {
      state.searchAddressArray = [];
    },
    setSearchAddress(state, searchAddress) {
      state.searchAddress = searchAddress;
    },
  },
  actions: {},
  getters: {},
});

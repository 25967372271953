import { loadYmap } from "vue-yandex-maps";
import {
  getCircle,
  getLOMInstance,
  getMapInstance,
  getPolyLine,
  getRectangle,
  getURLSearchParamsInstance,
  getUserPositionPoint,
  setCircle,
  setLOMInstance,
  setUserPositionPoint,
} from "@/services/nonReactiveGlobalVariables";
import axios from "axios";
import {
  BASE_URL,
  getExcelStationList,
  getStationDetails,
} from "@/services/requestsToAPI";
import store from "@/store/vueStore";

/**
 * Получение глобальной переменной ymaps
 */
async function getGlobalYandexMapVar() {
  await loadYmap({
    apiKey: "5bd40306-276a-4ab8-a95b-8ea18d0a14b0",
    lang: "ru_RU",
    coordorder: "latlong",
    enterprise: false,
    version: "2.1",
  });
}

/**
 * Создается новая точка ymaps.GeoObject
 *
 * @param {Number} latitude Широта
 * @param {Number} longitude Долгота
 * @return {ymaps.GeoObject} Возвращается геообъект типа Point
 */
function createNewPoint(latitude, longitude) {
  return new ymaps.GeoObject(
    {
      geometry: {
        type: "Point",
        coordinates: [latitude, longitude],
      },
      properties: {},
    },
    {
      preset: "islands#blueIcon",
      hintCloseTimeout: null,
    }
  );
}

/**
 * Построение нового маршрута
 *
 * @param {Number[]} route Точки по которому будет построен маршрут
 * @return {ymaps.multiRouter.MultiRoute} Новый маршрут
 */
function setNewRoute(route) {
  return new ymaps.multiRouter.MultiRoute(
    {
      referencePoints: route,
    },
    {
      boundsAutoApply: true,
      avoidTrafficJams: true,
      wayPointDraggable: false,
    }
  );
}

function getClosestDirect(startPoint, direction, distance) {
  const result = (direction * Math.PI) / 180;
  const mathRes = [Math.sin(result), Math.cos(result)];
  return (0,
  ymaps.coordSystem.geo.solveDirectProblem(startPoint, mathRes, distance)
    .pathFunction)(1).point;
}

/**
 * Создается новый объект из маршрута ymaps.Polyline
 *
 * @param {ymaps.multiRouter.MultiRoute} pathObject экземпляр маршрута
 * @return {ymaps.Polyline} экземпляр ymaps.Polyline
 */
function createNewPathPolyline(pathObject) {
  const polylineCoords = pathObject
    .getPaths()
    .toArray()
    .map(function (path) {
      return path.properties.get("coordinates");
    })
    .reduce(function (e, t) {
      return e.concat(t);
    }, []);
  return new ymaps.Polyline(polylineCoords, { visible: false });
}

/**
 * Показывает название места по координатам
 *
 * @param {Number[]} coords Координаты [longitude, latitude]
 * @return {ymaps.geocode} Название места
 */
function returnAddressNameByCoordinates(coords) {
  return ymaps
    .geocode(coords)
    .then((res) => res.geoObjects.get(0).getAddressLine());
}

/**
 * Возвращает координаты по названию места
 *
 * @param {String} addressName Название места
 * @return {Promise} Координаты по названию места
 */
async function returnCoordsByAddressName(addressName) {
  return ymaps.geocode(addressName).then(function (res) {
    return res.geoObjects.get(0).geometry.getCoordinates();
  });
}

/**
 * Показывает полное название места по первым введеным буквам
 *
 * @param {String} address
 * @return {ymaps.suggest} Полное название места
 */
function suggestSearch(address) {
  try {
    return axios.get(
      `https://suggest-maps.yandex.ru/v1/suggest?apikey=ee5d7049-ccb7-407e-a9a0-a56007016540&text=${address}`
    );
  } catch (error) {
    console.error("Ошибка: " + error);
  }
}

/**
 * Определяет, является ли устройство телефоном\планшетом
 *
 * @return {Boolean} Является\Не является
 */
function isMobile() {
  let check = false;
  let a = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    )
  )
    check = true;
  return check;
}

/**
 *  Установка на карте круга и позиционной точки пользователя
 *
 * @param {Number[]} coords Координаты [longitude, latitude]
 */
function setCircleAndUserPositionPoint(coords) {
  if (getUserPositionPoint()) {
    getUserPositionPoint().geometry.setCoordinates(coords);
    getCircle().geometry.setCoordinates(coords);
  } else {
    setUserPositionPoint(createNewPoint(coords[0], coords[1]));
    setCircle(
      new ymaps.Circle([coords, 1000], null, {
        visible: false,
      })
    );

    getMapInstance().geoObjects.add(getCircle());
    getMapInstance().geoObjects.add(getUserPositionPoint());
  }
}

/**
 * Получение границ круга
 *
 * @return {Number[Number[], Number[]]} Возвращает координаты границ круга
 */
function getCircleBounds() {
  return getCircle().geometry.getBounds();
}

/**
 * Получение ближайших точек для маршрута
 *
 * @param {[]} pointsResult
 * @param {ymaps.Rectangle} rectangle
 * @param {ymaps.Polyline} pathPolyline
 * @return {[Array]} Возвращаем отфильтрованный массив с ближайшими точками
 */
function getPointsForRoute(pointsResult, rectangle, pathPolyline) {
  const points = pointsResult.features.map((item) =>
    createNewPoint(item.geometry.coordinates[0], item.geometry.coordinates[1])
  );
  const coordsOfRoute = [];
  const result = ymaps.geoQuery(points).searchInside(rectangle);
  result.each(function (element) {
    const e = element.geometry.getCoordinates();
    pathPolyline.some(function (line) {
      return line.geometry.getClosest(e).distance < 1000;
    }) && coordsOfRoute.push(element.geometry.getCoordinates());
  });
  return pointsResult.features.filter((item) => {
    return coordsOfRoute.some((arrayItem) => {
      return (
        parseFloat(arrayItem[0]) === parseFloat(item.geometry.coordinates[0]) &&
        parseFloat(arrayItem[1]) === parseFloat(item.geometry.coordinates[1])
      );
    });
  });
}

function onShowSidebar() {
  if (!isMobile()) {
    store.commit("setSideBarVisible", true);
  }
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  if (params.has("emitents") && params.getAll("emitents")[0].length !== 0) {
    const cardParamsArr = new Set(params.getAll("emitents")[0].split(","));
    store.commit("setSelectedCardModel", [...cardParamsArr]);
  } else if (store.state.cardNumberFromPopup) {
    store.state.selectedCardModel[0] = store.state.cardNumberFromPopup;
  }
}

function setClassesToHTMLElement(classes, elementIds) {
  for (let elementId in elementIds) {
    document.getElementById(elementIds[elementId]).classList.add(...classes);
  }
}

function changeSideBarHight() {
  if (store.state.sidebarHeight <= window.innerHeight - 10) {
    store.commit("setSidebarHight", window.innerHeight);
    store.commit("setSideBarVisible", true);
  } else {
    store.commit("setSidebarHight", 40);
    store.commit("setSideBarVisible", false);
  }
}

/**
 * Объявление LoadingObjectManager
 *
 * @return ymaps.LoadingObjectManager
 */
function declareLOM() {
  const myIconContentLayout = ymaps.templateLayoutFactory.createClass(
    '<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}</div>'
  );
  const loadingObjectManager = new ymaps.LoadingObjectManager("", {
    clusterize: true,
    gridSize: 256,
    clusterHasBalloon: false,
    clusterIcons: [
      {
        href: store.state.filial.filial_info.clusterIcon,
        size: [45, 45],
        offset: [-20, -20],
      },
    ],
    clusterIconContentLayout: myIconContentLayout,
    clusterGroupByCoordinates: false,
    geoObjectOpenBalloonOnClick: false,
    splitRequests: false,
  });
  loadingObjectManager.objects.events.add("click", onObjectClick);

  getMapInstance().events.add("boundschange", function (event) {
    if (event.get("newZoom") > 15) {
      loadingObjectManager.options.set({
        clusterize: false,
      });
    } else {
      loadingObjectManager.options.set({
        clusterize: true,
      });
    }
  });

  setLOMInstance(loadingObjectManager);

  async function onObjectClick(e) {
    let objectId = e.get("objectId");
    let object = loadingObjectManager.objects.getById(objectId);

    const stationInfo = await getStationDetails(object.id);

    store.commit("setStationData", stationInfo);
    store.commit("setSideBarVisible", true);
    store.commit("setActiveTab", 1);
    if (isMobile()) {
      setClassesToHTMLElement(
        ["transition-all", "transition-duration-500"],
        ["phoneSidebar", "phoneSidebarBtn"]
      );
      changeSideBarHight();
      onShowSidebar();
    }
  }

  return loadingObjectManager;
}

function getLOMURLTemplate(params = "", dataLoadingModesType = "bbox=%b") {
  return `${BASE_URL}stations?${dataLoadingModesType}&${params.toString()}`;
}

function reloadLOMDataOrAddLOMOnMap() {
  if (getMapInstance().geoObjects.indexOf(getLOMInstance()) === -1) {
    getMapInstance().geoObjects.add(getLOMInstance());
  } else {
    getLOMInstance().reloadData();
  }
}

function createTrafficControl() {
  return new ymaps.control.TrafficControl({
    state: { trafficShown: false },
    options: {
      size: "small",
      position: {
        bottom: "auto",
        left: "10px",
        right: "auto",
        top: "335px",
      },
    },
  });
}

function toStation(latitude, longitude) {
  getMapInstance().setCenter([latitude, longitude], 21);
  store.commit("setSideBarVisible", false);
  if (isMobile()) {
    setClassesToHTMLElement(
      ["transition-all", "transition-duration-500"],
      ["phoneSidebar", "phoneSidebarBtn"]
    );
    changeSideBarHight();
  }
}

async function requestStationsIfRouteWasBuild(result) {
  const pointsForMap = new Set(
    getPointsForRoute(result, getRectangle(), getPolyLine())
  );
  const pointsForMapArray = [...pointsForMap];
  getLOMInstance().setUrlTemplate("");
  getLOMInstance().objects.removeAll();
  getLOMInstance().objects.add(pointsForMapArray);
  const stationIds = pointsForMapArray.map((item) => item.id);
  store.commit("setStationsIdsForNearestRouteStations", stationIds);
}

async function setPointsArrDependsOnAddressType(addressArr, coordType) {
  if (coordType === "addressNames") {
    const addresses = addressArr.map((item) => item.address);

    for (const address of addresses) {
      if (address) {
        const addressCoords = await returnCoordsByAddressName(address);
        store.commit("setRoutePointsArray", addressCoords);
      }
    }
  } else {
    const chunkSize = 2;
    for (let i = 0; i < addressArr.length; i += chunkSize) {
      const chunk = addressArr.slice(i, i + chunkSize);
      store.commit("setRoutePointsArray", chunk);
    }
  }
}

function appendFilters(params, coords, restrictBbox = false) {
  params.set("brand", store.state.selectedBrand);
  params.set("emitents", store.state.selectedCard);
  params.set("additional", store.state.selectedAdditional);
  params.set("service", store.state.selectedService);
  params.set("station_azs_type", store.state.stationTypeFilter);
  params.set("only_azs_stations", store.state.selectedOnlyAZSStations);
  if (!restrictBbox) {
    params.set(
      "bbox",
      `${coords.minLat},${coords.minLon},${coords.maxLat},${coords.maxLon}`
    );
  }
}

async function requestExcelStationsMixinMethod() {
  const params = getURLSearchParamsInstance();
  params.delete("page");
  params.delete("rows");
  appendFilters(params, store.state.newCoords);
  const queryParams = { params };
  return await getExcelStationList(
    { stationIds: store.state.selectedStationIds },
    queryParams
  );
}

function isSearchAddressArrayIsEmpty() {
  const isEmptyValue = (obj) => {
    return Object.values(obj).some((value) => value === "");
  };

  return (
    store.state.searchAddressArray.some(isEmptyValue) ||
    store.state.searchAddressArray.length === 0 ||
    store.state.searchAddressArray.length === 1
  );
}

function changeClusterForChepestStation(cheapestStations) {
  getLOMInstance().clusters.each(function (cluster) {
    for (let stationInCluster of cluster.properties.geoObjects) {
      if (cheapestStations.some((e) => e.id === stationInCluster.id)) {
        getLOMInstance().clusters.setClusterOptions(cluster.id, {
          clusterIcons: [store.state.filial.filial_info.clusterIconCheap],
        });
      }
    }
  });
}

function isSearchAddressArrayAndStationIdsIsEmpty() {
  return isSearchAddressArrayIsEmpty() || store.state.stationIds.length === 0;
}

function isSearchAddressArrayAndRoutePointsArrayIsEmpty() {
  return (
    isSearchAddressArrayIsEmpty() || store.state.routePointsArray.length === 0
  );
}

async function sendOnPostMessages(data, urlArray) {
  for (let url in urlArray) {
    window.postMessage(data, urlArray[url]);
  }
}

async function copyRouteWithPoints() {
  const urlArray = [
    "https://tanos.dt-teh.ru",
    "https://bc.expcard.ru",
    "https://lk.dt-teh.ru",
  ];
  const copyUrl = `${window.location.origin}?buildRoute=${store.state.routePointsArray}
          &selectedPoints=${store.state.stationIds}`;
  await sendOnPostMessages(copyUrl, urlArray);
  await navigator.clipboard.writeText(copyUrl);
}

async function copyRouteWithoutPoints() {
  const urlArray = [
    "https://tanos.dt-teh.ru",
    "https://bc.expcard.ru",
    "https://lk.dt-teh.ru",
  ];
  const copyUrl = `${window.location.origin}?buildRoute=${store.state.routePointsArray}`;
  await sendOnPostMessages(copyUrl, urlArray);
  await navigator.clipboard.writeText(copyUrl);
}

function openRouteInYandexMaps() {
  const generatedRoute = store.state.routePointsArray.join("~");

  if (isMobile()) {
    window.location = `yandexmaps://maps.yandex.ru/?rtext=${generatedRoute}`;
  } else {
    window.open(
      `https://maps.yandex.ru/?rtext=${generatedRoute}`,
      "_blank",
      "noreferrer"
    );
  }
}

export {
  changeClusterForChepestStation,
  openRouteInYandexMaps,
  isSearchAddressArrayAndRoutePointsArrayIsEmpty,
  isSearchAddressArrayIsEmpty,
  isSearchAddressArrayAndStationIdsIsEmpty,
  copyRouteWithPoints,
  copyRouteWithoutPoints,
  appendFilters,
  requestExcelStationsMixinMethod,
  setClassesToHTMLElement,
  setPointsArrDependsOnAddressType,
  requestStationsIfRouteWasBuild,
  toStation,
  createTrafficControl,
  changeSideBarHight,
  getLOMURLTemplate,
  reloadLOMDataOrAddLOMOnMap,
  onShowSidebar,
  declareLOM,
  createNewPathPolyline,
  getClosestDirect,
  getCircleBounds,
  setCircleAndUserPositionPoint,
  getGlobalYandexMapVar,
  createNewPoint,
  setNewRoute,
  returnAddressNameByCoordinates,
  returnCoordsByAddressName,
  suggestSearch,
  isMobile,
  getPointsForRoute,
};

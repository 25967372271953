import {
  getCircleBounds,
  returnAddressNameByCoordinates,
  returnCoordsByAddressName,
  setCircleAndUserPositionPoint,
} from "@/services/utils";
import { getMapInstance } from "@/services/nonReactiveGlobalVariables";

export const setPositionsMixins = {
  data: () => ({
    geolocationCode: undefined,
  }),
  methods: {
    async setUserPositionMixinMethod(updateSearchAddressItem = false) {
      const successCallback = async (position) => {
        const myCoords = [position.coords.latitude, position.coords.longitude];

        await this.userPositionMixinMethod(myCoords, updateSearchAddressItem);
        this.$store.commit("setSideBarVisible", false);
      };

      const errorCallback = async (error) => {
        console.error(error);
        this.geolocationCode = error.code;
      };

      const options = {
        enableHighAccuracy: true,
      };

      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        options
      );
      await this.requestStationsMixinMethod();
    },
    async setUserPositionOnClickMixinMethod(
      e,
      updateSearchAddressItem = false
    ) {
      let coordsOnClick = e.get("coords");
      await this.setMapDataMixinMethod(coordsOnClick, updateSearchAddressItem);
    },
    async setMapCenterToAddressMixinMethod(address) {
      const placeCenter = await returnCoordsByAddressName(address);
      getMapInstance().setCenter(placeCenter, 21);
      await this.setMapDataMixinMethod(placeCenter, address);
    },
    async userPositionMixinMethod(myCoords, updateSearchAddressItem) {
      await this.setMapDataMixinMethod(myCoords, null, updateSearchAddressItem);
      getMapInstance().setCenter(myCoords, 21);
    },
    async setMapDataMixinMethod(
      coords,
      address = null,
      updateSearchAddressItem
    ) {
      setCircleAndUserPositionPoint(coords);
      this.$store.commit("setMyPositionCoords", coords);
      this.$store.commit(
        "setSearchAddress",
        address || (await returnAddressNameByCoordinates(coords))
      );
      if (updateSearchAddressItem) {
        this.$store.commit("updateSearchAddressArray", {
          address: address || (await returnAddressNameByCoordinates(coords)),
          index: 0,
        });
      }
      if (this.$store.state.nearestStationsToRoute.length === 0) {
        const [minLatLon, maxLatLon] = getCircleBounds();
        this.$store.commit("setNewCoordsNearestStations", {
          minLat: minLatLon[0],
          maxLat: maxLatLon[0],
          minLon: minLatLon[1],
          maxLon: maxLatLon[1],
        });
        this.$store.commit("setNearestStations", []);
        await this.requestNearestStationsToUserMixinMethod();
      }
    },
  },
};

<template>
  <Toast class="w-22rem" position="bottom-left" />
  <div class="flex flex-column align-items-center justify-content-center">
    <div
      v-if="$store.state.routePointsArray.length <= 1"
      :class="`flex flex-column h-auto justify-content-between ${$store.state.addClassWidth}`"
    >
      <h2 class="text-center">
        Постройте маршрут, чтобы увидеть ближайшие точки к маршруту. Можете
        сделать двойное нажатие на карте, через информацию о станции или сделать
        это через вкладку "Маршрут"
      </h2>
      <Button
        label="Перейти к маршрутам"
        outlined
        @click="$store.commit('setActiveTab', 3)"
      />
    </div>
    <div
      v-else-if="$store.state.nearestStationsToRoute.length === 0"
      :class="`${$store.state.addClassWidth}`"
    >
      <div class="w-full">
        <div class="flex flex-column align-items-start w-full">
          <div class="w-full flex flex-row p-inputgroup">
            <InputText
              v-model="$store.state.nearestStationsToRouteSearchField"
              aria-describedby="help"
              class="w-full"
              placeholder="Поиск ближайших точек к маршруту"
              @keyup.enter="searchByAddressAndTitle"
            />
            <Button icon="pi pi-search" @click="searchByAddressAndTitle" />
          </div>
          <small id="help" class="text-xs mt-1"
            >Введите адрес или название</small
          >
        </div>
      </div>
      <h1 :class="`${$store.state.addClassWidth}`">
        Нет станций поблизости маршрута в пределах 1 км
      </h1>
    </div>
    <div v-else :class="`filter mb-5 ${$store.state.addClassWidth}`">
      <div class="w-full">
        <div class="flex flex-column align-items-start w-full">
          <div class="w-full flex flex-row p-inputgroup">
            <InputText
              v-model="$store.state.nearestStationsToRouteSearchField"
              aria-describedby="help"
              class="w-full"
              placeholder="Поиск ближайших точек к маршруту"
              @keyup.enter="searchByAddressAndTitle"
              @update:modelValue="checkInputIsEmpty"
            />
            <Button icon="pi pi-search" @click="searchByAddressAndTitle" />
          </div>
          <small id="help" class="text-xs mt-1"
            >Введите адрес или название</small
          >
        </div>
        <div class="my-3 flex flex-column justify-content-between h-3rem">
          <span class="font-bold text-sm">
            Всего точек: {{ getNearestStationsToRouteCount() }}
          </span>
          <span class="font-bold text-sm">
            Выделенных точек: {{ getSelectedPointsForExcel() }}
          </span>
        </div>
        <div class="w-full mt-3 flex flex-column">
          <RouteToolbar />
        </div>
      </div>
      <div
        v-for="item in $store.state.nearestStationsToRoute"
        :key="item.id"
        :class="`filter__block-flex filter__block my-4 ${$store.state.addClassWidth}`"
      >
        <Balloon :station-data="item" />
        <span
          class="flex justify-content-between align-items-center mt-3 h-2rem"
        >
          <Button
            label="Подробнее"
            outlined
            size="small"
            @click="toStation(item.latitude, item.longitude)"
          />
          <ToggleButtonComponent :station-id="item.id" />
        </span>
      </div>
    </div>
    <div>
      <Paginator
        v-if="
          $store.state.nearestStationsToRoute.length !== 0 &&
          $store.state.sideBarVisible
        "
        :pageLinkSize="3"
        :rows="10"
        :template="{
          '320px': 'PrevPageLink CurrentPageReport NextPageLink',
          '1000px':
            'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
          '1300px':
            'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          default:
            'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        }"
        :totalRecords="parseInt(getNearestStationsToRouteCount())"
        class="fixed bottom-0 left-0 border-noround w-full"
        currentPageReportTemplate="{currentPage} из {totalPages}"
        @page="updatePageNearestStationsRoute"
      />
    </div>
  </div>
</template>

<script>
import { getNearestStationsToRouteCount } from "@/services/nonReactiveGlobalVariables";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";
import { setPositionsMixins } from "@/mixins/setPositionsMixin";
import { getAddressesMixin } from "@/mixins/getAddressesMixin";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";
import Balloon from "@/components/UtilsComponents/Balloon.vue";
import ToggleButtonComponent from "@/components/UtilsComponents/ToggleButtonComponent.vue";
import {
  isMobile,
  requestStationsIfRouteWasBuild,
  toStation,
} from "@/services/utils";
import RouteToolbar from "@/components/UtilsComponents/RouteToolbar.vue";

export default {
  name: "NearestStationsToRoute",
  components: { RouteToolbar, ToggleButtonComponent, Balloon },
  mixins: [
    requestsAPIMixin,
    setPositionsMixins,
    getAddressesMixin,
    nearestStationsToRouteMixin,
  ],
  data: () => ({
    selectedNearestStationToRouteSort: undefined,
  }),
  methods: {
    isMobile,
    getSelectedPointsForExcel() {
      if (
        parseInt(this.$store.state.stationIds.length) >=
        getNearestStationsToRouteCount()
      ) {
        return getNearestStationsToRouteCount();
      } else {
        return parseInt(this.$store.state.stationIds.length);
      }
    },
    async checkInputIsEmpty(e) {
      if (e.length === 0) {
        const result = await this.requestStationsMixinMethod("", true);
        await requestStationsIfRouteWasBuild(result);
        await this.requestNearestListStationsMixinMethod("");
      }
    },
    getNearestStationsToRouteCount,
    async updatePageNearestStationsRoute(page) {
      await this.requestNearestListStationsMixinMethod(
        this.$store.state.nearestStationsToRouteSearchField,
        page.page + 1
      );
    },
    async searchByAddressAndTitle() {
      const result = await this.requestStationsMixinMethod(
        this.$store.state.nearestStationsToRouteSearchField,
        true
      );
      await requestStationsIfRouteWasBuild(result);
      await this.requestNearestListStationsMixinMethod(
        this.$store.state.nearestStationsToRouteSearchField
      );
      await this.requestCheapestStationsFuel();
    },
    toStation,
  },
};
</script>

<style lang="scss" scoped></style>

let mapInstance,
  route,
  circle,
  pointCollection,
  myPositionPoint,
  rectangleCollection,
  nearestStationsToRouteCount,
  LOM,
  rectangle,
  polyLine = undefined;

const url = new URL(window.location.href);
const urlSearchParams = new URLSearchParams(url.search);

function getNearestStationsToRouteCount() {
  return nearestStationsToRouteCount;
}

function getMapInstance() {
  return mapInstance;
}

function getLOMInstance() {
  return LOM;
}

function getURLInstance() {
  return url;
}

function getRectangle() {
  return rectangle;
}

function getPolyLine() {
  return polyLine;
}

function getURLSearchParamsInstance() {
  return urlSearchParams;
}

function getRectangleCollection() {
  return rectangleCollection;
}

function getPointCollection() {
  return pointCollection;
}

function getRoute() {
  return route;
}

function getCircle() {
  return circle;
}

function getUserPositionPoint() {
  return myPositionPoint;
}

function setNearestStationsToRouteCount(newCount) {
  nearestStationsToRouteCount = newCount;
}

function setRectangle(newRectangle) {
  rectangle = newRectangle;
}

function setPolyLine(newPolyLine) {
  polyLine = newPolyLine;
}

function setRectangleCollection(newRectangleCollection) {
  rectangleCollection = newRectangleCollection;
}

function setRoute(newRoute) {
  route = newRoute;
}

function setUserPositionPoint(newMyPositionPoint) {
  myPositionPoint = newMyPositionPoint;
}

function setPointCollection(newPointCollection) {
  pointCollection = newPointCollection;
}

function setCircle(newCircle) {
  circle = newCircle;
}

function setMapInstance(newMapInstance) {
  mapInstance = newMapInstance;
}

function setLOMInstance(newLOMInstance) {
  LOM = newLOMInstance;
}

export {
  getPolyLine,
  getRectangle,
  getRoute,
  getMapInstance,
  getCircle,
  getPointCollection,
  getUserPositionPoint,
  getRectangleCollection,
  getNearestStationsToRouteCount,
  getLOMInstance,
  getURLInstance,
  getURLSearchParamsInstance,
  setPolyLine,
  setRectangle,
  setMapInstance,
  setRoute,
  setCircle,
  setPointCollection,
  setUserPositionPoint,
  setRectangleCollection,
  setNearestStationsToRouteCount,
  setLOMInstance,
};

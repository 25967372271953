<template>
  <Toast class="w-20rem" position="bottom-left" />
  <div
    class="flex flex-column align-items-center w-full justify-content-center"
  >
    <div
      v-if="$store.state.stationData.length === 0"
      :class="`${$store.state.addClassWidth}`"
    >
      <h1>Выберите станцию на карте для получения детальной информации</h1>
    </div>
    <div v-else :class="`w-full ${$store.state.addClassWidth}`">
      <Balloon :station-data="$store.state.stationData" />
      <div
        :class="`py-3 flex justify-content-between ${$store.state.addClassWidth}`"
      >
        <Button
          :label="`${
            $store.state.searchAddressArray.length === 0
              ? 'Добавить маршрут'
              : 'Добавить точку к маршруту'
          }`"
          outlined
          size="small"
          @click="
            setRouteMixinMethodByLatLon(
              $store.state.stationData.latitude,
              $store.state.stationData.longitude
            )
          "
        />
        <ToggleButtonComponent :station-id="$store.state.stationData.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { setRouteMixin } from "@/mixins/setRouteMixin";
import Balloon from "@/components/UtilsComponents/Balloon.vue";
import ToggleButtonComponent from "@/components/UtilsComponents/ToggleButtonComponent.vue";

export default {
  name: "StationInfo",
  components: { ToggleButtonComponent, Balloon },
  mixins: [setRouteMixin],
};
</script>

<style lang="scss" scoped></style>

import { suggestSearch } from "@/services/utils";

export const getAddressesMixin = {
  data: () => ({
    suggestedAddresses: undefined,
  }),
  methods: {
    async getSuggestAddressMixinMethod(address) {
      const res = await suggestSearch(address);
      const results = res.data.results;

      if (results) {
        this.suggestedAddresses = results.map((item) => item.title.text);
      } else {
        this.suggestedAddresses = [];
      }
    },
  },
};

<template>
  <aside
    class="border-0 flex justify-content-end flex-row align-items-stretch fixed z-2 top-0 right-0 overflow-hidden"
  >
    <div v-if="!isMobile()">
      <Button
        class="h-3rem m-2 sm:m-2 md:m-3 lg:m-3 xl:m-3 shadow-3"
        @click="onShowSidebar"
      >
        <span
          class="p-button-icon p-button-icon-left pi pi-bars mr-0 sm:mr-0 md:mr-2 lg:mr-2 xl:mr-2"
        ></span>
        <span class="p-button-label hidden md:block xl:block lg:block sm:hidden"
          >Меню</span
        >
      </Button>
      <Sidebar
        v-model:visible="$store.state.sideBarVisible"
        :dismissable="false"
        :modal="false"
        :position="$store.state.sideBarPosition"
        class="w-full sm:w-25rem lg:w-30rem xl:w-30rem"
      >
        <template v-if="!isMobile()" #header>
          <div class="flex align-items-center gap-3 h-5rem">
            <Button
              v-if="$store.state.sideBarPosition === 'right'"
              v-tooltip.left="'Развернуть сайдбар'"
              icon="pi pi-window-maximize"
              @click="changeSidebarPosition('maximize')"
            />
            <Button
              v-else
              v-tooltip.right="'Свернуть сайдбар'"
              icon="pi pi-window-minimize "
              @click="changeSidebarPosition('minimize')"
            />
            <span class="font-bold">|</span>
            <span
              id="card-image"
              class="h-3rem bg-contain bg-no-repeat logo-sm"
            />
            <span class="font-bold">|</span>
            <div v-if="phoneNumber" class="font-bold">
              <a :href="`${phoneNumber}`">{{ phoneNumber }}</a>
              <br />
              <span>тел. поддержки</span>
            </div>
          </div>
        </template>
        <div v-if="isMobile()" class="header-main">
          <div
            class="flex w-full justify-content-evenly xl:flex-row lg:flex-row md:flex-row flex-column align-items-center"
          >
            <div class="header-main__first-block header-main_block">
              <div
                id="card-image"
                class="h-3rem bg-contain bg-no-repeat logo-sm"
              />
            </div>
            <div
              v-if="phoneNumber"
              class="flex flex-column align-items-center header-main_block"
            >
              <p class="header-main__p">Помощь на АЗС 24 часа</p>
              <p class="header-main__p header-main__p-tel">
                <a :href="`${phoneNumber}`">{{ phoneNumber }}</a>
              </p>
            </div>
          </div>
        </div>
        <TabView
          v-model:activeIndex="active"
          :scrollable="true"
          class="overflow-hidden"
          lazy
        >
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-filter" />
              <span class="pl-1 text-sm">Фильтры</span>
            </template>
            <Filters />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-gas-pump" />
              <span class="pl-1 text-sm">Станция</span>
            </template>
            <StationInfo />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass-location" />
              <span class="pl-1 text-sm">Ближайшие</span>
            </template>
            <NearestStationsToPosition />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-route" />
              <span class="pl-1 text-sm">Маршрут</span>
            </template>
            <Route />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-compass" />
              <span class="pl-1 text-sm">Точки</span>
            </template>
            <NearestStationsToRoute />
          </TabPanel>
        </TabView>
        <ScrollTop
          v-if="$store.state.activeTab !== 2"
          :threshold="250"
          class="w-2rem h-2rem text-xs border-round-lg"
          icon="pi pi-arrow-up"
          style="background-color: var(--primary-color)"
          target="parent"
        />
      </Sidebar>
    </div>
    <div v-else>
      <Button
        id="phoneSidebarBtn"
        :class="`w-full h-3rem fixed z-5 flex
        align-items-center justify-content-center flex-row ${transitionAnimation}`"
        :style="sidebarButtonStyle"
        @click="rollSideBar"
      >
        <span
          :class="`p-button-icon p-button-icon pi pi-angle-double-${arrowDirection}`"
        ></span>
        <span class="p-button-label block">{{ nameDirection }}</span>
      </Button>
      <Sidebar
        id="phoneSidebar"
        :class="`w-full ${transitionAnimation}`"
        :dismissable="false"
        :modal="false"
        :show-close-icon="false"
        :style="sidebarStyles"
        :visible="true"
        position="bottom"
        @touchend="onTouchEnd"
        @touchmove="onTouchMove"
        @touchstart="onTouchStart"
      >
        <div class="header-main">
          <div
            class="flex w-full justify-content-evenly xl:flex-row lg:flex-row md:flex-row flex-column align-items-center"
          >
            <div class="header-main__first-block header-main_block">
              <div
                id="card-image"
                class="h-3rem bg-contain bg-no-repeat logo-sm"
              />
            </div>
            <div
              v-if="phoneNumber"
              class="flex flex-column align-items-center header-main_block"
            >
              <p class="header-main__p">Помощь на АЗС 24 часа</p>
              <p class="header-main__p header-main__p-tel">
                <a :href="`${phoneNumber}`">{{ phoneNumber }}</a>
              </p>
            </div>
          </div>
        </div>
        <TabView
          v-model:activeIndex="active"
          :scrollable="true"
          class="overflow-hidden"
          lazy
        >
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-filter" />
              <span class="pl-1 text-sm">Фильтры</span>
            </template>
            <Filters />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-gas-pump" />
              <span class="pl-1 text-sm">Станция</span>
            </template>
            <StationInfo />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass-location" />
              <span class="pl-1 text-sm">Ближайшие</span>
            </template>
            <NearestStationsToPosition />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-route" />
              <span class="pl-1 text-sm">Маршрут</span>
            </template>
            <Route />
          </TabPanel>
          <TabPanel>
            <template #header>
              <font-awesome-icon icon="fa-compass" />
              <span class="pl-1 text-sm">Точки</span>
            </template>
            <NearestStationsToRoute />
          </TabPanel>
        </TabView>
        <ScrollTop
          v-if="$store.state.activeTab !== 2"
          :threshold="250"
          class="w-2rem h-2rem text-xs border-round-lg"
          icon="pi pi-arrow-up"
          style="background-color: var(--primary-color)"
          target="parent"
        />
      </Sidebar>
    </div>
  </aside>
</template>

<script>
import { setPositionsMixins } from "@/mixins/setPositionsMixin";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";
import { getAddressesMixin } from "@/mixins/getAddressesMixin";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";
import Route from "@/components/SideNavChildComponents/Route.vue";
import Filters from "@/components/SideNavChildComponents/Filters.vue";
import NearestStationsToPosition from "@/components/SideNavChildComponents/NearestStationsToPosition.vue";
import NearestStationsToRoute from "@/components/SideNavChildComponents/NearestStationsToRoute.vue";
import { changeSideBarHight, isMobile, onShowSidebar } from "@/services/utils";
import StationInfo from "@/components/SideNavChildComponents/StationInfo.vue";
import store from "@/store/vueStore";

export default {
  name: "SideNav",
  components: {
    StationInfo,
    NearestStationsToRoute,
    NearestStationsToPosition,
    Filters,
    Route,
  },
  mixins: [
    requestsAPIMixin,
    setPositionsMixins,
    getAddressesMixin,
    nearestStationsToRouteMixin,
  ],
  data: () => ({
    phoneNumber: null,
    isDragging: false,
    startY: 0,
    startHeight: 0,
    transitionAnimation: "",
    arrowDirection: "up",
    nameDirection: "Открыть",
    isSidebarRolledUp: false,
  }),
  async mounted() {
    await this.requestFilial();
    this.phoneNumber = this.$store.state.filial.phone;
  },
  computed: {
    active: {
      get() {
        return this.$store.state.activeTab;
      },
      set(value) {
        this.$store.commit("setActiveTab", value);
      },
    },
    sidebarStyles() {
      const maxHeight = window.innerHeight - 50;
      const minHeight = 40;

      const clampedHeight = Math.min(
        Math.max(this.$store.state.sidebarHeight, minHeight),
        maxHeight
      );

      return {
        height: clampedHeight + "px",
      };
    },
    sidebarButtonStyle() {
      const maxHeight = window.innerHeight - 50;
      const minHeight = 40;

      const clampedHeight = Math.min(
        Math.max(this.$store.state.sidebarHeight, minHeight),
        maxHeight
      );

      if (this.$store.state.sidebarHeight <= window.innerHeight - 10) {
        this.arrowDirection = "up";
        this.nameDirection = "Открыть";
      } else {
        this.arrowDirection = "down";
        this.nameDirection = "Скрыть";
      }

      return {
        bottom: clampedHeight - 25 + "px",
        left: 50 + "%",
        transform: "translate(-50%, -50%)",
      };
    },
  },
  methods: {
    onShowSidebar,
    isMobile,
    changeSidebarPosition(changeType) {
      if (changeType === "minimize") {
        this.$store.commit("setAddClassWidth", "w-full");
        this.$store.commit("setSidebarPosition", "right");
      } else {
        this.$store.commit("setAddClassWidth", "w-30rem");
        this.$store.commit("setSidebarPosition", "full");
      }
    },
    rollSideBar() {
      this.transitionAnimation = "transition-all transition-duration-500";
      changeSideBarHight();
      this.onShowSidebar();
    },
    onTouchStart(event) {
      this.transitionAnimation = "";
      this.isDragging = true;
      this.startY = event.touches[0].clientY;
      this.startHeight = this.$store.state.sidebarHeight;
      this.isSidebarRolledUp =
        this.$store.state.sidebarHeight >= window.innerHeight - 10;
    },
    onTouchMove(event) {
      onShowSidebar();
      let deltaY = 0;

      if (
        !this.isSidebarRolledUp &&
        this.$store.state.sidebarHeight >= window.innerHeight / 13
      ) {
        this.isDragging = false;
        this.transitionAnimation = "transition-all transition-duration-500";
        this.$store.commit("setSidebarHight", window.innerHeight);
        store.commit("setSideBarVisible", true);
      } else if (
        this.isSidebarRolledUp &&
        this.$store.state.sidebarHeight <= window.innerHeight - 10
      ) {
        this.isDragging = false;
        this.transitionAnimation = "transition-all transition-duration-500";
        this.$store.commit("setSidebarHight", 40);
        store.commit("setSideBarVisible", false);
      }

      if (this.isDragging) {
        deltaY = this.startY - event.touches[0].clientY;
        this.$store.commit("setSidebarHight", this.startHeight + deltaY);
      }
    },
    onTouchEnd() {
      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-sm {
  background-image: var(--logo-sm-url);
  width: var(--logo-width) !important;
}

.header-main__container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.header-main__second-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
}

.aside-sidenav {
  background-color: rgba(0, 0, 0, 0);
}

.filter {
  height: 100%;

  &:nth-child(1) {
    margin: 0;
  }
}

.filter__block {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;

  &:nth-child(1) {
    margin: 0;
  }
}

.filter__autocomplete-flex {
  display: flex;
  margin-top: 10px;
}

.filter__div {
  margin: 0 0 15px 0;
}

.filter__div-input {
  width: 100%;
}

.filter__block-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;

  span {
    padding-left: 10px;
  }
}

.nearest-statiotion-info {
  display: flex;
  flex-direction: column;
  height: 20vh;
  justify-content: space-between;
}

.p-button .p-button-raised {
  box-shadow: 0 5px 6px -2px rgb(0 0 0 / 32%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.checkbox-filter-fade-enter-active {
  transition: all 0.3s ease-out;
}

.checkbox-filter-fade-leave-active {
  transition: all 1s ease-out;
}

.checkbox-filter-fade-enter-from,
.checkbox-filter-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

/* media */

@media screen and (max-width: 475px) {
  .p-multiselect {
    width: 80vw;
  }
}

@media screen and (max-width: 400px) {
  .header-main__container {
    flex-direction: column;
  }
}
</style>

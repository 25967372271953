<template>
  <div class="ballon">
    <div class="py-1 balloon-container__header balloon-div">
      <h1 class="my-2">
        <strong>{{ stationData.brand }}</strong>
      </h1>
      <p class="subheader">
        <font-awesome-icon icon="fa-map" />
        {{ stationData.address }}
      </p>
      <p class="subheader">
        <font-awesome-icon icon="fa-compass" />
        {{ stationData.latitude }},
        {{ stationData.longitude }}
      </p>
      <p v-if="stationData.additional" class="subheader">
        <font-awesome-icon icon="fa-car" />
        {{ stationData.additional }}
      </p>
      <p v-if="stationData.services" class="subheader">
        <font-awesome-icon icon="fa-gas-pump" />
        {{ stationData.services }}
      </p>
    </div>
    <div
      v-if="stationData.full_services.length !== 0"
      class="py-2 flex-column balloon-div"
    >
      <div class="flex flex-column">
        <div v-for="service in stationData.full_services">
          <p
            v-if="
              $store.state.cheapestStationsFuel.some(
                (e) => e.id === stationData.id
              ) &&
              $store.state.selectedCheapestFuel?.code === service.service_code
            "
            class="text-green-600"
          >
            {{ service.service_name }}:
            <strong>{{ service.price_value }}</strong> на
            {{ service.price_date }}
          </p>
          <p v-else>
            {{ service.service_name }}:
            <strong>{{ service.price_value }}</strong> на
            {{ service.price_date }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="stationData.phone_pay || stationData.barcode_pay"
      class="balloon-div"
    >
      <div
        v-if="stationData.phone_pay"
        class="flex flex-row align-items-center"
      >
        <font-awesome-icon icon="fa-mobile" />
        <p class="subheader pl-1">Оплата из машины</p>
      </div>
      <div
        v-if="stationData.barcode_pay"
        class="flex flex-row align-items-center"
      >
        <font-awesome-icon icon="fa-barcode" />
        <p class="subheader pl-1">Оплата по штрихкоду</p>
      </div>
    </div>
    <div
      v-if="
        stationData.AZSGazpromneft ||
        stationData.OPTI24 ||
        stationData.OPTI ||
        stationData.AZSFranchisees ||
        stationData.AZSPartners
      "
      class="balloon-div"
    >
      <div
        v-if="stationData.AZSGazpromneft"
        class="flex flex-row align-items-center"
      >
        <p class="subheader pl-1">АЗС Газпромнефть</p>
      </div>
      <div v-if="stationData.OPTI24" class="flex flex-row align-items-center">
        <p class="subheader pl-1">ОПТИ 24</p>
      </div>
      <div
        v-if="stationData.AZSFranchisees"
        class="flex flex-row align-items-center"
      >
        <p class="subheader pl-1">АЗС Франчайзи ОПТИ</p>
      </div>
      <div
        v-if="stationData.AZSPartners"
        class="flex flex-row align-items-center"
      >
        <p class="subheader pl-1">АЗС Партнеров ОПТИ</p>
      </div>
      <div v-if="stationData.OPTI" class="flex flex-row align-items-center">
        <p class="subheader pl-1">ОПТИ</p>
      </div>
    </div>
    <div class="balloon-div">
      <div class="flex flex-row align-items-center">
        <font-awesome-icon
          :class="`${stationData.active ? 'text-green-500' : 'text-red-500'}`"
          :icon="stationData.active ? `fa-unlock` : `fa-lock`"
        />
        <p
          :class="`subheader pl-1 ${
            stationData.active ? 'text-green-500' : 'text-red-500'
          }`"
        >
          {{ stationData.active ? "Открыта" : "Закрыта" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Balloon",
  props: { stationData: Object },
};
</script>

<style lang="scss" scoped>
p {
  margin: 10px 0;
  color: #4a4a4a;
}

.balloon-container {
  padding: 5px 0;
}

.balloon-div {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.footer {
  padding: 10px;
}
</style>

<template>
  <div class="w-full flex align-items-center flex-column">
    <div
      v-if="
        $store.state.myPositionCoords.length === 0 &&
        $store.state.routePointsArray.length === 0
      "
      :class="`flex flex-column h-auto justify-content-between ${$store.state.addClassWidth}`"
    >
      <h2 class="text-center">
        Укажите ваше местоположение для отображения ближайших станций или
        кликнете на карте для установления местоположения
      </h2>
      <Button
        label="Указать местоположение"
        outlined
        @click="setUserPositionMixinMethod(false)"
      />
    </div>
    <div
      v-else-if="
        $store.state.nearestStations.length === 0 &&
        $store.state.routePointsArray.length === 0
      "
    >
      <h1>Нет станций поблизости в пределах 1 км</h1>
    </div>
    <div
      v-else-if="$store.state.routePointsArray.length > 0"
      :class="`flex flex-column h-auto justify-content-between ${$store.state.addClassWidth}`"
    >
      <h2 class="text-center">
        При построеном маршруте возможно увидеть только ближайшие станции АЗС
        вдоль маршрута
      </h2>
      <Button
        label="Перейти к точкам"
        outlined
        @click="$store.commit('setActiveTab', 4)"
      />
    </div>
    <div v-else class="filter">
      <div
        v-for="item in $store.state.nearestStations"
        :key="item.id"
        class="filter__block-flex filter__block"
      >
        <Balloon :station-data="item" class="my-2" />
        <Button
          class="mt-2"
          label="Подробнее"
          outlined
          size="small"
          @click="toStation(item.latitude, item.longitude)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { setPositionsMixins } from "@/mixins/setPositionsMixin";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";
import { getAddressesMixin } from "@/mixins/getAddressesMixin";
import { toStation } from "@/services/utils";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";
import Balloon from "@/components/UtilsComponents/Balloon.vue";

export default {
  name: "NearestStationsToPosition",
  mixins: [
    requestsAPIMixin,
    setPositionsMixins,
    getAddressesMixin,
    nearestStationsToRouteMixin,
  ],
  components: { Balloon },
  methods: {
    toStation,
  },
};
</script>

<style lang="scss" scoped></style>

import {
  getCheapestStationsFuel,
  getFilial,
  getNearestStationsToUser,
  getStationList,
  getStationsPointForRoute,
} from "@/services/requestsToAPI";
import {
  getLOMInstance,
  getMapInstance,
  getURLSearchParamsInstance,
} from "@/services/nonReactiveGlobalVariables";
import {
  appendFilters,
  changeClusterForChepestStation,
  declareLOM,
  getLOMURLTemplate,
  reloadLOMDataOrAddLOMOnMap,
} from "@/services/utils";
import store from "@/store/vueStore";

export const requestsAPIMixin = {
  methods: {
    async requestNearestListStationsMixinMethod(search = "", page = "1") {
      const params = getURLSearchParamsInstance();
      appendFilters(params, this.$store.state.newCoords);
      params.set("search", search);
      params.set("page", page);
      const queryParams = {
        params,
      };
      this.$store.commit(
        "setNearestStationsToRoute",
        await getStationsPointForRoute(
          { stationIds: this.$store.state.selectedStationIds },
          queryParams
        )
      );
    },
    async requestCheapestStationsFuel() {
      const params = getURLSearchParamsInstance();
      if (this.$store.state.selectedCheapestFuel?.code) {
        params.set(
          "cheap_service",
          this.$store.state.selectedCheapestFuel.code
        );
        const queryParams = {
          params,
        };
        this.$store.commit(
          "setCheapestStationsFuel",
          await getCheapestStationsFuel(
            { stationIds: this.$store.state.selectedStationIds },
            queryParams
          )
        );
      }

      const cheapestStations = this.$store.state.cheapestStationsFuel;

      if (
        this.$store.state.cheapestStationsFuel &&
        this.$store.state.selectedCheapestFuel
      ) {
        for (let station_info in cheapestStations) {
          getLOMInstance().objects.setObjectOptions(
            cheapestStations[station_info].id,
            {
              iconImageHref:
                cheapestStations[station_info].cheap_fuel_info.icon
                  .cheapIconImageHref,
              iconImageSize:
                cheapestStations[station_info].cheap_fuel_info.icon
                  .iconImageSize,
              zIndex: 50000000,
            }
          );
          changeClusterForChepestStation(cheapestStations);
        }
        getMapInstance().events.add(
          ["actiontick", "actionend", "actionbegin"],
          () => {
            if (this.$store.state.selectedCheapestFuel) {
              changeClusterForChepestStation(cheapestStations);
            }
          }
        );
      } else {
        params.delete("cheap_service");
        this.$store.commit("setCheapestStationsFuel", []);
        for (let station_info in cheapestStations) {
          getLOMInstance().objects.setObjectOptions(
            cheapestStations[station_info].id,
            {
              iconImageHref:
                cheapestStations[station_info].cheap_fuel_info.icon
                  .defaultIconImageHref,
              iconImageSize: [42, 42],
            }
          );
        }
        getLOMInstance().clusters.each(function (cluster) {
          getLOMInstance().clusters.setClusterOptions(cluster.id, {
            clusterIcons: [
              {
                href: store.state.filial.filial_info.clusterIcon,
                size: [45, 45],
                offset: [-20, -20],
              },
            ],
          });
        });
      }
    },
    async requestStationsMixinMethod(
      search = "",
      returnStations = false,
      paramsData = undefined
    ) {
      const params = getURLSearchParamsInstance();
      params.set("search", search);
      if (paramsData && "stationIds" in paramsData) {
        params.set("id", paramsData["stationIds"].join(","));
        params.delete("bbox");
      }
      const queryParams = { params };
      if (!getLOMInstance()) {
        declareLOM();
      }
      if (!returnStations) {
        if (params.has("bbox")) {
          params.delete("bbox");
        }
        appendFilters(params, this.$store.state.newCoords, true);
        getLOMInstance().setUrlTemplate(getLOMURLTemplate(params.toString()));
        reloadLOMDataOrAddLOMOnMap();
      } else {
        appendFilters(params, this.$store.state.newCoords, false);
        return await getStationList(queryParams);
      }
    },
    async requestNearestStationsToUserMixinMethod() {
      const params = getURLSearchParamsInstance();
      appendFilters(params, this.$store.state.newCoordsNearestStations);
      const queryParams = { params };
      this.$store.commit(
        "setNearestStations",
        await getNearestStationsToUser(queryParams)
      );
    },
    async requestFilial() {
      const url = document.location.host; // Получаем урл в адресной строке
      let lookupValue = undefined;
      switch (url) {
        case "locator.dt-teh.ru":
          lookupValue = "delta";
          break;
        case "loc.expcard.ru":
          lookupValue = "express";
          break;
        case "locator.specoilserv.ru":
          lookupValue = "specoil";
          break;
        case "locator.bak.ru":
          lookupValue = "polniy_bak";
          break;
        default:
          lookupValue = "polniy_bak";
          break;
      }
      this.$store.commit("setFilial", await getFilial(lookupValue));
    },
  },
};

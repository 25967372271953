<template>
  <PopUp
    v-if="showPopup"
    @get-chosen-card="getChosenCard"
    @init-map-data-on-popup-close="getMapDataOnClosedPopup"
  />
  <ProgressSpinner
    v-if="$store.state.showSpinner"
    animationDuration="3s"
    class="absolute z-5"
    fill="var(--base)"
    strokeWidth="8"
    style="
      height: 50px;
      top: 50%;
      margin-top: -25px;
      left: 50%;
      width: 50px;
      margin-left: -25px;
    "
  />
  <div
    :class="`flex shadow-3 border-round-lg ${
      isMobile() ? 'w-auto' : 'w-15rem'
    } sm:w-20rem md:w-25rem
    lg:w-25rem xl:w-25rem m-2 sm:m-2 md:m-3 lg:m-3 xl:m-3 p-inputgroup fixed top-0 left-0 z-2`"
  >
    <AutoComplete
      v-model="$store.state.searchAddress"
      :delay="500"
      :pt="{
        panel: { class: 'w-4rem' },
      }"
      :suggestions="suggestedAddresses"
      class="w-full"
      input-id="locationValue"
      placeholder="Ваше местоположение"
      @complete="getSuggestAddressMixinMethod($store.state.searchAddress)"
      @keyup.enter="
        setMapCenterToAddressMixinMethod($store.state.searchAddress)
      "
    />
    <Button
      :disabled="!$store.state.searchAddress"
      class="w-5rem"
      icon="pi pi-search"
      @click="setMapCenterToAddressMixinMethod($store.state.searchAddress)"
    />
    <Button
      class="w-5rem"
      icon="pi pi-map-marker"
      @click="setUserPositionMixinMethod(false)"
    />
  </div>
  <YandexMap
    :behaviors="yandexOptions.behaviors"
    :bounds="initCoords"
    :controls="yandexOptions.controls"
    :events="yandexOptions.events"
    :options="yandexOptions.mapOptions"
    :zoom="12.5"
    class="fixed top-0 bottom-0 left-0 right-0 z-1 w-full h-full"
    @boundschange="getObjectsFromAPIOnBoundsChange"
    @click="setUserPositionOnClickMixinMethod($event, false)"
    @created="onCreatedMap"
    @dblclick="setRouteOnDblClick"
  />
  <SideNav />
</template>
<script>
import { YandexClusterer, YandexMap, YandexMarker } from "vue-yandex-maps";
import SideNav from "@/components/SideNav.vue";
import PopUp from "@/components/Popup.vue";
import {
  createNewPoint,
  createTrafficControl,
  getGlobalYandexMapVar,
  isMobile,
  reloadLOMDataOrAddLOMOnMap,
  returnAddressNameByCoordinates,
} from "@/services/utils";
import {
  getMapInstance,
  getPointCollection,
  getRoute,
  getURLInstance,
  getURLSearchParamsInstance,
  setMapInstance,
  setPointCollection,
} from "@/services/nonReactiveGlobalVariables";
import {
  getAdditionals,
  getBrands,
  getEmitents,
  getServices,
} from "@/services/requestsToAPI";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";
import { setPositionsMixins } from "@/mixins/setPositionsMixin";
import { getAddressesMixin } from "@/mixins/getAddressesMixin";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";
import { setRouteMixin } from "@/mixins/setRouteMixin";

export default {
  components: {
    PopUp,
    YandexMap,
    YandexMarker,
    YandexClusterer,
    SideNav,
  },
  mixins: [
    requestsAPIMixin,
    setPositionsMixins,
    getAddressesMixin,
    nearestStationsToRouteMixin,
    setRouteMixin,
  ],
  data: () => ({
    yandexOptions: {
      clusterOptions: {
        gridSize: 256,
        margin: 20,
        viewportMargin: 512,
      },
      mapOptions: {
        suppressObsoleteBrowserNotifier: true,
      },
      markerOptions: {
        iconLayout: "default#image",
        hasHint: false,
        openHintOnHover: false,
        outline: false,
      },
      behaviors: [
        "drag",
        "scrollZoom",
        "multiTouch",
        "rightMouseButtonMagnifier",
      ],
      controls: ["rulerControl", "zoomControl"],
      events: [
        "boundschange",
        "balloonclose",
        "balloonopen",
        "dblclick",
        "click",
      ],
    },
    initCoords: [
      [55.717771511516816, 37.50107133251953],
      [55.78931354450113, 37.717364667480474],
    ],
    showPopup: true,
  }),
  async beforeMount() {
    const params = getURLSearchParamsInstance();
    await this.requestFilial();
    try {
      this.$store.commit(
        "setCards",
        await getEmitents(params, this.$store.state.filial.code)
      );
      this.$store.commit("setBrands", await getBrands());
      this.$store.commit("setServices", await getServices(params));
      this.$store.commit("setAdditionals", await getAdditionals(params));
    } catch (error) {
      console.error(error);
    }
  },
  async mounted() {
    const [url, params] = [getURLInstance(), getURLSearchParamsInstance()];
    await getGlobalYandexMapVar();
    setPointCollection(new ymaps.GeoObjectCollection());

    const isEmitentsParams =
      params.has("emitents") && params.getAll("emitents")[0].length !== 0;

    const isRouteBuildParams =
      params.has("buildRoute") && params.getAll("buildRoute")[0].length !== 0;

    // if (params.has("cheapFuel") && params.getAll("cheapFuel")[0].length !== 0) {
    //   const cheapFuel = params.getAll("cheapFuel")[0].split(",");
    //   this.$store.commit("setSelectedCheapestFuel", {
    //     name: cheapFuel[0],
    //     code: cheapFuel[1],
    //   });
    //   this.requestCheapestStationsFuel();
    // }

    if (isEmitentsParams) {
      const cardParamsArr = new Set(params.getAll("emitents")[0].split(","));
      this.$store.commit("setSelectedCard", [...cardParamsArr]);
      this.$store.commit(
        "setAdditionals",
        await getAdditionals(params, [...cardParamsArr])
      );
      this.$store.commit(
        "setServices",
        await getServices(params, [...cardParamsArr])
      );
      this.showPopup = false;
      this.getMapDataOnClosedPopup();
    }

    if (isRouteBuildParams) {
      const buildRouteCoords = params.getAll("buildRoute")[0].split(",");
      const stationIds = params.has("selectedPoints")
        ? params.getAll("selectedPoints")[0].split(",")
        : undefined;
      const paramsData = {};
      if (stationIds) {
        paramsData.stationIds = stationIds;
      }
      this.showPopup = false;
      const chunkSize = 2;
      for (let i = 0; i < buildRouteCoords.length; i += chunkSize) {
        const chunk = buildRouteCoords.slice(i, i + chunkSize);

        this.$store.commit(
          "setSearchAddressArray",
          await returnAddressNameByCoordinates(chunk)
        );
      }
      await this.setRouteByArrOfPointsMixin(buildRouteCoords, "addressCoords");
      this.getNearestStationsToRouteMixinMethod(paramsData);
      getRoute().model.events.add("requestsuccess", async () => {
        reloadLOMDataOrAddLOMOnMap();
      });
    }

    url.search = params.toString();
    window.history.replaceState(null, null, url.origin);
    params.delete("buildRoute");
    params.delete("selectedPoints");
  },
  methods: {
    isMobile,
    async onCreatedMap(e) {
      const [url, params] = [getURLInstance(), getURLSearchParamsInstance()];
      setMapInstance(await e);

      const trafficControl = createTrafficControl();
      e.controls.add(trafficControl);
    },
    async setRouteOnDblClick(e) {
      const coordsOnClick = e.get("coords");

      getPointCollection().add(createNewPoint(...coordsOnClick));
      getMapInstance().geoObjects.add(getPointCollection());

      if (getPointCollection().getLength() >= 1) {
        await this.setRouteMixinMethodByLatLon(...coordsOnClick);
      }
    },
    getMapDataOnClosedPopup() {
      const [minLatLon, maxLatLon] = this.initCoords;
      const newCoords = {
        minLat: minLatLon[0],
        maxLat: maxLatLon[0],
        minLon: minLatLon[1],
        maxLon: maxLatLon[1],
      };
      this.$store.commit("setNewCoords", newCoords);
      this.requestStationsMixinMethod();
    },
    async getChosenCard(card, showPopup) {
      const params = getURLSearchParamsInstance();
      this.$store.commit("setSelectedCard", card);
      this.$store.commit("setAdditionals", await getAdditionals(params, card));
      this.$store.commit("setServices", await getServices(params, card));
      if (!showPopup) {
        this.getMapDataOnClosedPopup();
      }
    },
    getObjectsFromAPIOnBoundsChange(event) {
      const { newZoom, newBounds } = event.originalEvent;

      this.$store.commit("setBounds", newBounds);

      if (this.$store.state.boundsCheck) {
        const [minLat, minLon] = newBounds[0];
        const [maxLat, maxLon] = newBounds[1];
        const newCoords = { minLat, maxLat, minLon, maxLon };

        this.$store.commit("setNewCoords", newCoords);
      }
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: Helvetica, sans-serif;
  font-size: 1em;
}

.ymaps-2-1-79-balloon {
  width: 330px !important;
  min-height: auto !important;
}

.ymaps-2-1-79-balloon__content > ymaps {
  width: 100% !important;
  height: 100% !important;
}
</style>
